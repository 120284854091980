#siteContainer {
    height: 100%;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

#siteHeader {
    background-color: #111;
    text-align: center;
    padding: 0 20px;
}

h2 {
    margin: 12px 0px;
}

#siteContent {
    flex: 1;
    background-color: #222;
    position: relative;
    width: 100%;
    height: 100%;
}
#siteContent {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0.1;
    }
    60% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
     }
}

#mapContainer {
    position: absolute;
    top: 30px;
    bottom: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
    /* width: 20em; */
}
@media only screen and (max-width: 600px) {
    #mapContainer {
        /* width: 20em;     */
    }
}
@media only screen and (min-width: 600px) {
    #mapContainer {
        /* width: 30em;     */
    }
}
@media only screen and (min-width: 768px) {
    #mapContainer {
        /* width: 40em;     */
    }
}
@media only screen and (min-width: 992px) {
    #mapContainer {
        /* width: 50em;     */
    }
}
@media only screen and (min-width: 1200px) {
    #mapContainer {
        /* width: 50em;     */
    }
}

