
html,
body,
#root {
    padding: 0;
    margin: 0;
    height:100%;
    height: -webkit-fill-available;
}

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #eee;
    background-color: black;
}